import {
  Button,
  Card,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import WarningTag from "../../components/WarningTag";
import { useSelector } from "react-redux";
import { priceInRupees } from "../../helper/helper";
import { post, uploadImg } from "../../helper/api";
import { useForm } from "antd/es/form/Form";
import { BsCopy } from "react-icons/bs";
import CopyToClipboard from "react-copy-to-clipboard";

function AddCash() {
  const { user, settings } = useSelector((s) => s.userReducer);
  const [form] = useForm();
  const [drawerOpen, setDrawerOpen] = useState();
  const [file, setFile] = useState();
  const [tab, setTab] = useState("upi");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const handleImageChange = (e) => {
    try {
      const img = e.target.files[0];
      setFile(img);
    } catch (error) {
      message.error(error.message);
    }
  };

  const onSubmit = async (vals) => {
    const res = await post("/funds/init-payment", { amount: vals?.amount });
    console.log(res);
    if (res?.success) {
      window.location.href = res?.payment_url;
    }
    return;
    setData(vals);
    setDrawerOpen(true);
  };
  const onFinish = async (vals) => {
    var amount = vals?.amount;
    if (!amount) {
      return message.error("Enter amount!");
    }
    if (isNaN(amount)) {
      return message.error("Amount should be a number");
    }
    amount = Number(vals?.amount);
    if (amount < settings?.min_deposit) {
      return message.error(
        `Enter minimum amount ${priceInRupees(settings?.min_deposit)}`
      );
    }
    if (!user?.kyc_verified && amount > 1000) {
      return message.error("First complete kyc to add money above 1000");
    }

    if (!file) {
      return message.error("Select payment proof!");
    }
    setLoading(true);
    if (file) {
      const imgup = await uploadImg(file);
      if (imgup?.success) {
        vals.user_proof = imgup?.url;
        const res = await post("/funds/create", vals);
        if (res?.success) {
          form.resetFields();
          setFile(null);
          Modal.success({
            content: res?.message,
          });
          setDrawerOpen(false);
        } else {
          message.error(res?.message);
        }
        setLoading(false);
      } else {
        setLoading(false);
        message.error(imgup?.message);
      }
    }
  };
  return (
    <>
      {/* <WarningTag
        text={
          "Notice:- यदि आप किसी भी पेमेंट का बैंक से Complain डालते है तो आपके खाते को Block कर दिया जायेगा ! इसलिए किसी और से अपने Ludo ID में पैसे न डलवाये ! और यदि आप खुद जान भूझकर बैंक से रिफंड लेने के लिए Complain डालते है तो आपकी Ludo ID पूर्णतः बंद कर दी जाएगी !"
        }
      /> */}

      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        placement="bottom"
        size="large"
        height={"100%"}
        title={`Add Amount ${priceInRupees(form.getFieldValue("amount"))}`}
        styles={{ body: { padding: 5 } }}
      >
        <Card
          type="inner"
          title={"Pay To"}
          tabList={[
            { label: "Upi", key: "upi" },
            { label: "Qr", key: "qr" },
          ]}
          activeTabKey={tab}
          onTabChange={(e) => setTab(e)}
        >
          <div className="flex flex-col gap-2">
            {tab === "upi" && (
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <Typography.Text className="text-lg font-semibold">
                    Name:-
                  </Typography.Text>
                  <Typography.Text className="text-lg ">
                    {settings?.name || "Ludo Boy"}
                  </Typography.Text>
                </div>
                <div className="flex items-center gap-2">
                  <Typography.Text className="text-lg font-semibold">
                    UPI ID:-
                  </Typography.Text>
                  <Typography.Text className="text-lg ">
                    {settings?.upi}
                  </Typography.Text>
                  <CopyToClipboard
                    text={settings?.upi}
                    onCopy={() => message.success("Upi Id Copied")}
                  >
                    <BsCopy className="text-PRIMARY" size={20} />
                  </CopyToClipboard>
                </div>
                <div className="flex items-center gap-2">
                  <Typography.Text className="text-lg font-semibold">
                    Min Amount:-
                  </Typography.Text>
                  <Typography.Text className="text-lg ">
                    {priceInRupees(settings?.min_deposit)}
                  </Typography.Text>
                </div>
                <div className="flex items-center gap-2">
                  <Typography.Text className="text-lg font-semibold">
                    Max Amount:-
                  </Typography.Text>
                  <Typography.Text className="text-lg ">
                    {priceInRupees(settings?.max_deposit)}
                  </Typography.Text>
                </div>
              </div>
            )}
            {tab === "qr" && (
              <Image
                src={settings?.qr}
                className=" object-contain"
                width={250}
              />
            )}

            <Typography.Text className="capitalize text-[17px] font-bold mt-4">
              याहा स्क्रीनशॉट डाले
            </Typography.Text>
            <Input type="file" onChange={handleImageChange} id="proof" />
          </div>

          <Button
            type="default"
            className="btn-primary w-full mt-3 bg-green-800 text-white"
            onClick={() => onFinish(data)}
            loading={loading}
          >
            Submit
          </Button>
        </Card>
      </Drawer>
      <div className="mt-4">
        <Card
        // title="Amount"
        >
          <Form
            layout="vertical"
            onFinish={onSubmit}
            className="mt-2"
            form={form}
          >
            <div className="flex items-center">
              <Form.Item
                label={
                  <Typography.Text className="text-lg font-bold">
                    Amount
                  </Typography.Text>
                }
                name={"amount"}
                help={`Min: ${priceInRupees(
                  settings?.min_deposit
                )}, Max: ${priceInRupees(settings?.max_deposit)}`}
                rules={[{ required: true }]}
                className="flex-1"
              >
                <Input
                  className="input-primary w-full rounded-none rounded-l-md"
                  placeholder="100"
                  inputMode="numeric"
                />
              </Form.Item>

              <Button
                type="default"
                className="btn-primary rounded-none  mt-[12px] bg-green-800 text-white rounded-r-md"
                htmlType="submit"
                loading={loading}
              >
                SUBMIT
              </Button>
            </div>
            {/* <WarningTag
              text={
                "30 मिनट से पुराना स्क्रीनशॉट अपलोड करने पर पेमेंट नहीं डाला जाएगा"
              }
            /> */}
          </Form>
        </Card>
      </div>

      {/* <Card className="mt-2 font-semibold text-red-500 ">
        <b className="text-black">पैसे डालने का तरीका </b>
        <br />
        1.⁠ ⁠सबसे पहले आप को I'd में जितने पैसे डालने है वो अमाउंट (पैसे) डाल कर
        सबमिट करे।
        <br />
        2.⁠ ⁠फिर आप को UPI ID और स्कैनर (QR) को कॉपी करके जितना अमाउंट डाला है
        उतना पेमेंट डाल कर स्क्रीनशॉट लेकर I'd पर सबमिट कर दे।
        <br />
        3.⁠ ⁠आप का पेमेंट I'd में 2–3 मिनट मे डाल दिया जाएगा।
        <br />
        4.⁠ ⁠पेमेंट करने के बाद 40 मिनट मे स्क्रीनशॉट सबमिट करे नही तो आप का
        पेमेंट मान्य नहीं होगा।
        <br />
      </Card> */}
    </>
  );
}

export default AddCash;
