import { Button, Card, Form, Input, message } from "antd";
import React, { useState } from "react";
import { get, post } from "../../helper/api";
import { LOCALDATAKEYS } from "../../helper/Constants";
import { useSearchParams } from "react-router-dom";

function Login() {
  const [data, setData] = useState({});
  const [step, setStep] = useState(1);
  const [type, setType] = useState("new");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(false);
  const [searchParams,setSearchParams]=useSearchParams()
const referBy = searchParams.get("referBy")
  const onSubmit = async (values) => {
    setData((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
    setLoading(true);
    if (step === 1) {
      const res = await post("/auth/send-otp", values);
      if (res?.success) {
        setLoading(false);
        setStep(2);
        // setOtp(res?.otp)
      
        setType(res?.type);
      }
    }
    if (step === 2) {
      const res = await post("/auth/verify-otp", values);
      if (res?.success) {
        message.success("Logged In Successfully!")
        localStorage.setItem(LOCALDATAKEYS.AUTH_TOKEN,res?.token)
        window.location.reload()
        
      }else{
        message.error(res?.message)
      }
      setLoading(false);
    }
  };
  return (
    <div className="mt-16">
      <Card title="Login">
        <Form layout="vertical" onFinish={onSubmit} initialValues={{
          "referral":referBy||""
        }}>
          <Form.Item label="Mobile" name={"mobile"} rules={[{required:true,message:"Enter a vaild mobile number!",max:10}]}>
            <Input className="input-primary" disabled={step===2}/>
          </Form.Item>
          {type === "new" && step === 2 && (
            <>
              <Form.Item label="Name" name={"name"} rules={[{required:true,message:"Enter a vaild name!"}]}>
                <Input className="input-primary" />
              </Form.Item>
              <Form.Item label="Referral" name={"referral"}>
                <Input className="input-primary" placeholder="Optional"/>
              </Form.Item>
            </>
          )}
          {step === 2 && (
            <Form.Item label="Otp" name={"otp"} rules={[{required:true,message:"Enter otp",max:6}]} help={`Otp sent to ${data?.mobile} `}>
              <Input className="input-primary" maxLength={6}/>
            </Form.Item>
          )}

          <Button
            type="primary"
            className="btn-primary w-full mt-2"
            htmlType="submit"
            loading={loading}
          >
            Next
          </Button>
        </Form>
      </Card>
    </div>
  );
}

export default Login;
