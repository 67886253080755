import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import socket from "../../helper/socket";
import { useSelector } from "react-redux";
import { get, put, uploadImg } from "../../helper/api";
import { DEFAULT_USER_IMAGE } from "../../helper/Configs";
import {
  Button,
  Card,
  Form,
  Input,
  Radio,
  Skeleton,
  Spin,
  Typography,
  message,
} from "antd";
import { BsLightningChargeFill } from "react-icons/bs";
import {
  BATTLE_RESPONSES,
  BATTLE_STATUS,
  SOCKET_EMITS,
} from "../../helper/Constants";
import { useForm } from "antd/es/form/Form";
import WarningTag from "../../components/WarningTag";
import CopyToClipboard from "react-copy-to-clipboard";
import { priceInRupees } from "../../helper/helper";

function BattleRoom() {
  const { user } = useSelector((s) => s.userReducer);
  const { battleId } = useParams();
  const [battle, setBattle] = useState();
  const [updating, setUpdating] = useState(false);
  const [responded, setResponded] = useState(false);
  const [response, setResponse] = useState("");
  const [changingValue, setChangingValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [file, setFile] = useState();
  const [form] = useForm();
  const [form2] = useForm();
  const navigate = useNavigate();

  const getBattle = async () => {
    setLoading(true);
    const res = await get(`/battle/${battleId}`);

    if (res?.success) {
      setBattle(res?.battle);
    }
    setLoading(false);
  };

  const updateRoomCode = async (values) => {
    setUpdating(true);
    const res = await put(`/battle/update-room-code/${battleId}`, values);
    if (res?.success) {
      setBattle(res?.battle);
    }
    setUpdating(false);
  };

  const onResponse = async (vals) => {
    if (responded) {
      return message.error("You already responsded");
    }
    if (user?.key_id === battle?.createrId) {
      vals.createrResponded = true;
      vals.createrResponseTime = new Date(Date.now());
      if (vals?.createrResponse === BATTLE_RESPONSES.win) {
        if (!file) {
          return message.error("Please select win screenshot");
        }
      }
    }
    if (user?.key_id === battle?.joinerId) {
      vals.joinerResponded = true;
      vals.joinerResponseTime = new Date(Date.now());
      if (vals?.joinerResponse === BATTLE_RESPONSES.win) {
        if (!file) {
          return message.error("Please select win screenshot");
        }
      }
    }

    setSubmiting(true);
    if (file) {
      const res = await uploadImg(file);
      if (res?.success) {
        if (user?.key_id === battle?.joinerId) {
          vals.joiner_win_proof = res?.url;
        }
        if (user?.key_id === battle?.createrId) {
          vals.creater_win_proof = res?.url;
        }
      }
    }

    // return console.log(vals);
    const res = await put(`/battle/submit-response/${battleId}`, vals);
    if (res?.success) {
      setBattle(res?.battle);
      setFile(null);
      getBattle();
      message.success("Response submited");
      navigate(`/battles/${battle?.game}`);
    } else {
      message.error(res?.message);
    }
    setSubmiting(false);
  };

  const handleImageChange = (e) => {
    try {
      setFile(e.target.files[0]);
    } catch (error) {}
  };

  useEffect(() => {
    socket.on(SOCKET_EMITS.UPDATE_ROOM_CODE, async (data) => {
      if (data?.battleId == battleId && !loading && !battle?.roomCode) {
        await getBattle();
      }
    });
  }, []);

  useEffect(() => {
    if (battleId) {
      getBattle();
    }
  }, [battleId]);
  useEffect(() => {
    if (battle) {
      if (battle?.roomCode) {
        form.setFieldValue("roomCode", battle?.roomCode);
      }
      const creater = battle?.createrId;
      const joiner = battle?.joinerId;
      if (user?.key_id === creater) {
        if (battle?.createrResponded) {
          setResponded(true);
          setResponse(battle?.createrResponse);
        }
      }
      if (user?.key_id === joiner) {
        if (battle?.joinerResponded) {
          setResponded(true);
          setResponse(battle?.joinerResponse);
        }
      }
    }
  }, [battle]);

  useEffect(() => {
    if (changingValue) {
      if (
        Object.keys(changingValue)?.at(0) === "createrResponse" ||
        Object.keys(changingValue)?.at(0) === "joinerResponse"
      ) {
        setResponse(changingValue[Object.keys(changingValue)?.at(0)]);
      }
    }
  }, [changingValue]);

  if (!battle) {
    return (
      <div className="flex flex-col gap-2">
        <Card loading></Card>
        <Card loading></Card>
        <Card loading></Card>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 ">
      {/* <WarningTag
        text={`
        पॉपुलर का रूम कोड डालने पर यूजर का बैलेंस जीरो कर दिया जायेगा`}
      /> */}
      <WarningTag
        text={
          " Notice:- पॉपुलर का रूम कोड डालने पर यूजर का बैलेंस जीरो कर दिया जायेगा 🙏 (User's balance will be reduced to zero on entering Popular's room code)"
        }
      />

      <BetweenCard battle={battle} />
      {!responded && battle?.status !== BATTLE_STATUS.complete && (
        <div className="w-full flex flex-col items-center mt-10">
          <Form
            layout="vertical"
            className="w-[80%]"
            onFinish={updateRoomCode}
            form={form}
          >
            {!battle?.roomCode ? (
              <Form.Item
                label="Room Code"
                name={"roomCode"}
                rules={[{ required: true, message: "Room Code Required!" }]}
              >
                {user?.key_id !== battle?.createrId && !battle?.roomCode ? (
                  <span className="flex flex-col items-center gap-3">
                    <Spin />
                    <Typography.Text>Waiting for room code..</Typography.Text>
                  </span>
                ) : (
                  <Input
                    className="input-primary"
                    disabled={
                      user?.key_id !== battle?.createrId || battle?.roomCode
                    }
                    maxLength={8}
                  />
                )}
              </Form.Item>
            ) : (
              <div className="flex flex-col items-center gap-2 mb-4">
                <Typography.Text className="text-center w-full text-lg font-bold ">
                  Room Code
                </Typography.Text>
                <Typography.Text className="text-center w-full text-3xl font-bold outline outline-1 outline-gray-300 rounded-lg p-2">
                  {battle?.roomCode}
                </Typography.Text>
              </div>
            )}
            {battle?.roomCode && (
              <CopyToClipboard
                text={battle?.roomCode}
                onCopy={() => message.success("Copied")}
              >
                <Button className="w-full btn-primary" type="primary">
                  Copy Code
                </Button>
              </CopyToClipboard>
            )}
            {user?.key_id === battle?.createrId && !battle?.roomCode && (
              <Button
                className="btn-primary w-full"
                type="primary"
                htmlType="submit"
                loading={updating}
              >
                Submit
              </Button>
            )}
          </Form>
        </div>
      )}
      <div>
        <WarningTag
          text={`
       Notice:- सभी यूजर'स गेम समापत होने के बाद रिजल्ट विन/लूज़ जरूर सबमिट करे रिजल्ट सबमिट न करने या गलत रिजल्ट सबमिट करने पर 25 की पेनल्टी लगा दी जाएगी`}
        />
        <WarningTag
          text={`
         Notice:- यदि आपका स्क्रीनशॉट गलत होता ह या रिजल्ट गलत होता ह तो गेम सीधा कैंसिल कर दिया जायेगा`}
        />
      </div>

      {responded || battle?.status === BATTLE_STATUS.complete ? (
        <WarningTag
          text={
            battle?.status === BATTLE_STATUS.complete
              ? `Winner decalred for this battle`
              : `You have submited response for this battle ${response}`
          }
        />
      ) : (
        <div className="p-2">
          <Form
            form={form2}
            layout="vertical"
            onFinish={onResponse}
            onValuesChange={(e) => setChangingValue(e)}
          >
            <Form.Item
              name={
                user?.key_id === battle?.createrId
                  ? "createrResponse"
                  : user?.key_id === battle?.joinerId
                  ? "joinerResponse"
                  : ""
              }
              label="Response"
              rules={[{ required: true, message: "Please Select Response" }]}
            >
              <Radio.Group disabled={responded}>
                {battle?.roomCode && (
                  <>
                    <Radio value={BATTLE_RESPONSES.win}>Win</Radio>
                    <Radio value={BATTLE_RESPONSES.lose}>Lose</Radio>
                  </>
                )}
                <Radio value={BATTLE_RESPONSES.cancel}>Cancel</Radio>
              </Radio.Group>
            </Form.Item>

            {response === BATTLE_RESPONSES.win && (
              <Form.Item label="Upload Screenshot">
                <Input type="file" onChange={handleImageChange} />
              </Form.Item>
            )}

            {!responded && (
              <Button
                htmlType="submit"
                className="btn-primary "
                type="primary"
                loading={submiting}
                disabled={submiting}
              >
                Submit
              </Button>
            )}
          </Form>
        </div>
      )}
    </div>
  );
}

function BetweenCard({ battle }) {
  return (
    <div className="flex gap-2 items-center mt-4">
      <div className="flex flex-col gap-2 items-center flex-1">
        <img
          src={DEFAULT_USER_IMAGE}
          alt="user"
          className="w-[70px] h-[70px] rounded-full"
        />
        <Typography.Text>
          {battle?.creater_name || battle?.createrId}
        </Typography.Text>
      </div>
      <div className="flex flex-col gap-2 items-center ">
        <BsLightningChargeFill size={40} className="text-red-600" />
        <Typography.Text className="font-bold text-red-500">
          V/S
        </Typography.Text>
        <Typography.Text className="font-bold text-green-500 text-lg">
          {priceInRupees(battle?.amount)}
        </Typography.Text>
      </div>

      <div className="flex flex-col gap-2 items-center flex-1">
        <img
          src={DEFAULT_USER_IMAGE}
          alt="user"
          className="w-[70px] rounded-full"
        />
        <Typography.Text>
          {battle?.joiner_name || battle?.joinerId}
        </Typography.Text>
      </div>
    </div>
  );
}

export default BattleRoom;
